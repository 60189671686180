import { Mesh, MeshBasicMaterial, RingGeometry } from 'three'
import ARButton from './ARButton'
import globalVariables from '../globalVariables'

export default class ImmersiveAR {
  constructor({ webglContext }) {
    this.webglContext = webglContext
    this.canCheckFloor = true
    this.isAndroid = this.checkAndroid()

    globalVariables.startImmersiveAR = this.startImmersiveAR.bind(this)

    if (this.isAndroid) {
      this.addArButton()
      this.addReticle()
    } else this.initModelViewer()
  }

  checkAndroid () {
    return /(android)/i.test(navigator.userAgent)
  }

  startImmersiveAR() {
    this.webglContext.canUpdate = false
    window.dispatchEvent(new CustomEvent('destroy-camera'))

    if (this.isAndroid) {
      this.arButtonClass.onButtonClick()
      this.webglContext.renderer.setAnimationLoop(this.update.bind(this))
    } else {
      this.modelViewer.activateAR()
    }
  }

  initModelViewer() {
    const arButton = document.createElement('button')
    arButton.disabled = true
    arButton.textContent = 'Next'
    arButton.classList.add('ar')

    const parent = document.body.querySelector('.arena-section .scan__button')
    const button = parent.querySelector('button')

    button.style.display = 'none'
    parent.appendChild(arButton)

    this.modelViewer = document.body.querySelector('model-viewer')
  }

  addArButton() {
    this.arButtonClass = new ARButton({
      renderer: this.webglContext.renderer,
      sessionInit: { requiredFeatures: [ 'hit-test' ] }
    })

    this.arButtonClass.createButton().then((arButton) => {
      const parent = document.body.querySelector('.arena-section .scan__button')
      const button = parent.querySelector('button')

      button.style.display = 'none'
      if (arButton.textContent === 'START AR') {
        arButton.textContent = 'Next'
        arButton.disabled = true
        arButton.classList.add('ar')
      }

      parent.appendChild(arButton)
    })
  }

  addReticle() {
    this.controller = this.webglContext.renderer.xr.getController(0)
    this.controller.addEventListener('select', this.onSelect.bind(this))

    this.webglContext.scene.add(this.controller)
    this.reticle = new Mesh(
      new RingGeometry( 0.14, 0.17, 32 ).rotateX(- Math.PI / 2),
      new MeshBasicMaterial({ color: '#fda033' })
    )

    this.reticle.matrixAutoUpdate = false
    this.reticle.visible = false
    this.webglContext.scene.add(this.reticle)
  }

  onSelect() {
    if (this.reticle.visible) {
      if (!this.arenaModel) this.arenaModel = globalVariables.models.filter((aModel) => aModel.name === 'arena')[0]
      this.arenaModel.model.position.setFromMatrixPosition(this.reticle.matrix)
      this.webglContext.scene.add(this.arenaModel.model)
      this.canCheckFloor = false
      this.reticle.visible = false
      this.arenaModel.model.visible = true
    }
  }

  getHitTestSource() {
    if (!this.hitTestSource) {
      const session = this.webglContext.renderer.xr.getSession()

      session.requestReferenceSpace('viewer').then((referenceSpace) => {
        session.requestHitTestSource({ space: referenceSpace }).then((source) => {
          this.hitTestSource = source
        })
      })

      session.addEventListener( 'end', () => {
        this.hitTestSource = null
      })
    }
  }

  update(timestamp, frame ) {
    if (frame) {
      if (this.canCheckFloor) {
        const referenceSpace = this.webglContext.renderer.xr.getReferenceSpace()

        if (!this.hitTestSource) this.getHitTestSource()
        if (this.hitTestSource) {
          const hitTestResults = frame.getHitTestResults(this.hitTestSource)

          if (hitTestResults.length) {
            const hit = hitTestResults[0]

            this.reticle.visible = true
            this.reticle.matrix.fromArray(hit.getPose(referenceSpace).transform.matrix)
          } else this.reticle.visible = false
        }
      }
    }

    this.webglContext.renderer.render(this.webglContext.scene, this.webglContext.camera)
  }
}