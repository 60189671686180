import { WebGLRenderer, Scene, sRGBEncoding, PerspectiveCamera, AmbientLight, AnimationMixer, Object3D, Clock, DirectionalLight, FrontSide, Mesh } from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import globalVariables from './globalVariables'
import ImmersiveAR from './webgl/ImmersiveAR'

export default class WebglContext {
  constructor() {
    this.clock = new Clock()
    this.canUpdate = true

    this.createRenderer()
    this.addLoaders()
    this.createRootObject()
    this.update()

    this.immersiveAR = new ImmersiveAR({ webglContext: this })

    globalVariables.loadModel = this.loadModel.bind(this)
    globalVariables.onNFTMarkerFoundFirstTime.push(this.onNFTMarkerFoundFirstTime.bind(this))
    globalVariables.onNFTMarkerLost.push(this.onNFTMarkerLost.bind(this))
  }

  createRenderer() {
    this.targetCanvas = document.body.querySelector('#canvas')
    this.renderer = new WebGLRenderer({
      canvas: this.targetCanvas,
      alpha: true,
      antialias: true,
      logarithmicDepthBuffer: true,
      physicallyCorrectLights: true,
      outputEncoding: sRGBEncoding
    })

    this.renderer.xr.enabled = true

    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.setSize(window.innerWidth, window.innerHeight)

    this.scene = new Scene()
    this.camera = new PerspectiveCamera(70, window.innerWidth / window.innerHeight, 0.01, 3000)
    this.camera.matrixAutoUpdate = false

    this.ambientLight = new AmbientLight(0xffffff, 1.0)
    this.directionalLight = new DirectionalLight(0xffffff, 1)
    this.directionalLight.position.set(10, 100, 40)
    
    this.scene.add(this.camera)
    this.scene.add(this.ambientLight)
    this.scene.add(this.directionalLight)
  }

  addLoaders() {
    const dracoLoader = new DRACOLoader()
    dracoLoader.setDecoderPath('../static/dracoLoader/')

    this.gltfLoader = new GLTFLoader()
    this.gltfLoader.setDRACOLoader(dracoLoader)
  }

  createRootObject() {
    this.root = new Object3D()
    this.scene.add(this.root)

    this.root.matrixAutoUpdate = false
  }

  loadModel(url, name) {
    return new Promise((resolve) => {
      this.gltfLoader.load(url, (gltf) => {
        const model = gltf.scene
        const materials = []

        if (name === 'arena') {
          this.scene.add(model)
        } else {
          if (name === 'paragon') {
            model.traverse((child) => {
              if (child.name === 'HeavyEnergyBlaster01' || child.name === 'Scorp01Legs') {
                child.material.side = FrontSide
                child.material.transparent = true
                child.material.opacity = 0

                materials.push(child.material)
              }
            })
            model.position.x = 106.849333333
            model.position.y = 118.364
            model.scale.set(0.4, 0.4, 0.4)
          } else {
            model.children[0].material.transparent = true
            model.children[0].material.opacity = 0
            materials.push(model.children[0].material)

            model.position.x = 105.833333333
            model.position.y = 149.817666667
            model.scale.set(0.11, 0.11, 0.11)
          }

          model.rotation.x -= -90 * (Math.PI/180)
        }

        model.name = name
        model.visible = false

        const animation = gltf.animations[0]
        let action = null
        let mixer = null

        if (animation) {
          mixer = new AnimationMixer(model)

          action = mixer && mixer.clipAction(animation)
          action.stop()
        }
  
        name !== 'arena' && this.root.add(model)
  
        globalVariables.models.push({
          name,
          model,
          animation: action,
          mixer,
          materials
        })

        window.dispatchEvent(new CustomEvent('loaded-elt'))

        resolve(model)
      })
    })
  }

  onNFTMarkerFoundFirstTime(name) {
    this.currentModel = globalVariables.models.filter((aModel) => aModel.name === name)[0]
    this.currentModel.animation && this.currentModel.animation.play()

    console.log(`Play ${this.currentModel.name} animation`)
  }

  onNFTMarkerLost() {
    console.log(`Stop ${this.currentModel.name} animation`)

    this.currentModel.animation && this.currentModel.animation.stop()
    this.currentModel = null
  }

  update() {
    if (!this.canUpdate) return

    const delta = this.clock.getDelta()

    this.currentModel && this.currentModel.mixer && this.currentModel.mixer.update(delta)
    this.renderer.render(this.scene, this.camera)

    requestAnimationFrame(this.update.bind(this))
  }
}