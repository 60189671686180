import Splitting from 'splitting'
import { gsap } from 'gsap'

export default class ScanSection {
  constructor({ el }) { 
    this.el = el

    this.getElems()
  }

  getElems() {
    this.textWrapper = this.el.querySelector('.end-experience__text')
    this.text = this.textWrapper.querySelector('.text')
    this.links = this.el.querySelectorAll('a')
  }

  enter() {
    this.el.classList.remove('hide')
    this.showAll()
  }

  showAll() {
    const tl = gsap.timeline()

    this.splittedText = Splitting({ target: this.text, by: 'words' })
    
    tl.set(this.text, { alpha: 1 })
    tl.to(this.textWrapper, {
      delay: 0.2,
      alpha: 1,
      duration: 0.4,
      ease: 'power2.out'
    })

    tl.to([this.splittedText[0].words, this.links], {
      delay: 0.5,
      y: 0,
      alpha: 1,
      pointerEvents: 'all',
      ease: 'expo.out',
      duration: 0.8,
      stagger: 0.075
    }, '-=0.1')
  }
}
