const globalVariables = {
  onNFTMarkerLoad: [],
  onNFTMarkerLoaded: [],
  onNFTMarkerFound: [],
  onNFTMarkerFoundFirstTime: [],
  onNFTMarkerLost: [],
  markers: [],
  models: []
}

export default globalVariables