import globalVariables from '../globalVariables'
import NFTMarker from './NFTMarker'

export default class ARManager {
  constructor(video, input_width, input_height, webglContext) {
    this.markers = []
    this.video = video
    this.input_width = input_width
    this.input_height = input_height
    this.webglContext = webglContext
    this.interpolationFactor = this.isAndroid() ? 10 : 20
  
    globalVariables.addNFTMarkers = this.addNFTMarkers.bind(this)

    this.camera_para = '../../static/artoolkitNFT2/camera_para.dat'
    this.canvas_process = document.createElement('canvas')
    this.context_process = this.canvas_process.getContext('2d')

    this.load()

    this.trackedMatrix = {
      delta: [
          0, 0, 0, 0,
          0, 0, 0, 0,
          0, 0, 0, 0,
          0, 0, 0, 0
      ],
      interpolated: [
          0, 0, 0, 0,
          0, 0, 0, 0,
          0, 0, 0, 0,
          0, 0, 0, 0
      ]
    }
  }

  interpolate (world) {
    for (let i = 0; i < 16; i++) {
      this.trackedMatrix.delta[i] = world[i] - this.trackedMatrix.interpolated[i]
      this.trackedMatrix.interpolated[i] = this.trackedMatrix.interpolated[i] + this.trackedMatrix.delta[i] / this.interpolationFactor
    }

    return this.trackedMatrix.interpolated
  }

  isAndroid () {
    return /(android)/i.test(navigator.userAgent)
  }

  isMobile () {
    return /Android|mobile|iPad|iPhone/i.test(navigator.userAgent)
  }
  
  setMatrix (matrix, value) {
    var array = []
    for (var key in value) array[key] = value[key]
  
    if (typeof matrix.elements.set === 'function') matrix.elements.set(array)
    else matrix.elements = [].slice.call(array)
  }

  load() {
    this.vw = this.input_width
    this.vh = this.input_height

    this.pscale = 320 / Math.max(this.vw, this.vh / 3 * 4)
    this.sscale = this.isMobile() ? window.outerWidth / this.input_width : 1

    this.sw = this.vw * this.sscale
    this.sh = this.vh * this.sscale

    this.w = this.vw * this.pscale
    this.h = this.vh * this.pscale
    this.pw = Math.max(this.w, this.h / 3 * 4)
    this.ph = Math.max(this.h, this.w / 4 * 3)
    this.ox = (this.pw - this.w) / 2
    this.oy = (this.ph - this.h) / 2

    this.canvas_process.style.clientWidth = this.pw + "px"
    this.canvas_process.style.clientHeight = this.ph + "px"
    this.canvas_process.width = this.pw
    this.canvas_process.height = this.ph

    this.webglContext.renderer.setSize(this.sw, this.sh);
  }

  addNFTMarkers(urls, names) {
    return new Promise((resolve) => {
      this.names = names
      this.markerFound = false
      this.worker = new Worker('../../static/artoolkitNFT2/wasm_worker/artoolkit.wasm_multi_worker.js')
      this.worker.onmessage = ((ev) => {
        const msg = ev.data

        if (ev.data.type === 'wasm') this.worker.postMessage({ type: "load", pw: this.pw, ph: this.ph, camera_para: this.camera_para, markerUrls: urls })
        if (msg.type === 'loaded') this.onNFTMarkerLoad(msg)
        if (msg.type === 'endLoading') {
          this.endLoading(msg)
          window.dispatchEvent(new CustomEvent('loaded-elt'))

          resolve()
        }
        if (msg.type === 'found') this.found(msg)
        if (msg.type === 'not found' && this.markerFound) this.lost()

        this.process()
      })
    })
  }

  onNFTMarkerLoad(msg) {
    const proj = JSON.parse(msg.proj)
    const ratioW = this.pw / this.w
    const ratioH = this.ph / this.h

    proj[0] *= ratioW
    proj[4] *= ratioW
    proj[8] *= ratioW
    proj[12] *= ratioW
    proj[1] *= ratioH
    proj[5] *= ratioH
    proj[9] *= ratioH
    proj[13] *= ratioH

    this.setMatrix(this.webglContext.camera.projectionMatrix, proj)

    globalVariables.onNFTMarkerLoad.forEach((func) => func(msg))
  }

  found(msg) {
    if (!this.markerFound) {
      this.markerFound = true
      this.currentMarker = this.markers.filter((aMarker) => aMarker.id === Number(msg.index))[0]

      this.trackedMatrix.interpolated = JSON.parse(msg.matrixGL_RH)

      globalVariables.onNFTMarkerFoundFirstTime.forEach((func) => func(this.currentMarker.name))
    }

    this.world = this.interpolate(JSON.parse(msg.matrixGL_RH))
    this.setMatrix(this.webglContext.root.matrix, this.world)
  }

  lost() {
    this.markerFound = false
    this.world = null

    globalVariables.onNFTMarkerLost.forEach((func) => func())
  }

  endLoading(msg) {
    msg.markersIds.forEach((id) => {
      const marker = new NFTMarker({
        id,
        name: this.names[id],
        worker: this.worker
      })

      this.markers.push(marker)
    })

    globalVariables.markers = this.markers

    this.names = null
    globalVariables.onNFTMarkerLoaded.forEach((func) => func(msg))
  }

  process() {
    this.context_process.fillStyle = 'black';
    this.context_process.fillRect(0, 0, this.pw, this.ph);
    this.context_process.drawImage(this.video, 0, 0, this.vw, this.vh, this.ox, this.oy, this.w, this.h);

    const imageData = this.context_process.getImageData(0, 0, this.pw, this.ph)

    this.worker.postMessage({ type: 'process', imagedata: imageData }, [imageData.data.buffer])
  }

  update() {}
}