import EnterSection from './EnterSection'
import ScanSection from './ScanSection'
import EndExperience from './EndExperience'

export default class StepManager {
  constructor() {
    this.onStepEnd = this.onStepEnd.bind(this)

    this.steps = []
    this.currentStepIndex = 0

    this.createSteps()
    this.setNewStep(this.currentStepIndex)
  }

  createSteps() {
    this.steps.push(new EnterSection({ el: document.body.querySelector('.start-experience'), onStepEnd: this.onStepEnd}))
    this.steps.push(new ScanSection({ el: document.body.querySelector('.cards-section'), onStepEnd: this.onStepEnd, name: 'cards' }))
    this.steps.push(new ScanSection({ el: document.body.querySelector('.paragon-section'), onStepEnd: this.onStepEnd, name: 'paragon' }))
    this.steps.push(new ScanSection({ el: document.body.querySelector('.arena-section'), onStepEnd: this.onStepEnd, name: 'arena' }))
    this.steps.push(new EndExperience({ el: document.body.querySelector('.end-experience') }))
  }

  onStepEnd() {
    this.currentStepIndex += 1
    this.setNewStep(this.currentStepIndex)
  }

  setNewStep(index) {
    this.currentStep = this.steps[index]
    this.currentStep.enter()
  }
}