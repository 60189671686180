import globalVariables from '../globalVariables'
import Splitting from 'splitting'
import { gsap } from 'gsap'

export default class EnterSection {
  constructor({ el, onStepEnd }) {
    this.bindMethods()
    this.getElems()

    this.el = el
    this.loadedElts = 0
    this.currentProgress  = { value: 0 }

    this.onStepEnd = onStepEnd
    this.canLeave = false
  }

  splitTexts() {
    this.splittedButtonValue = Splitting({ target: this.buttonValue, by: 'chars' })
    this.splittedButtonText = Splitting({ target: this.buttonText, by: 'chars' })
  }

  bindMethods() {
    this.onEltLoaded = this.onEltLoaded.bind(this)
    this.onButtonClick = this.onButtonClick.bind(this)
  }

  enter() {
    this.addEvents()
    this.showText()
    this.progressTo(5)
  }

  showText() {
    this.splittedText = Splitting({ target: this.text, by: 'words' })
    gsap.set(this.text, { alpha: 1 })

    gsap.to(this.splittedText[0].words, {
      y: 0,
      alpha: 1,
      ease: 'expo.out',
      duration: 0.6,
      stagger: 0.075
    })
  }

  start() {
    this.promises = [
      globalVariables.loadModel('../static/models/colonel.glb', 'colonel'),
      globalVariables.loadModel('../static/models/destroyer.glb', 'destroyer'),
      globalVariables.loadModel('../static/models/panther.glb', 'panther'),
      globalVariables.loadModel('../static/models/paragon.glb', 'paragon'),
      globalVariables.loadModel('../static/models/arena-small.glb', 'arena'),
      globalVariables.addNFTMarkers(
        [
          '../static/NFT/colonel/colonel',
          '../static/NFT/destroyer/destroyer',
          '../static/NFT/panther/panther',
          '../static/NFT/paragon/paragon-middle'
        ],
        [
          'colonel',
          'destroyer',
          'panther',
          'paragon'
        ]
      )
    ]

    Promise.all(this.promises)
  }

  getElems() {
    this.logo = document.body.querySelector('.start-experience__logo')
    this.text = document.body.querySelector('.start-experience__text')
    this.button = document.body.querySelector('.start-experience__button button')
    this.buttonValue = this.button.querySelector('span.value')
    this.buttonText = this.button.querySelector('span.start')
    this.background = document.body.querySelector('.start-experience__background')
  }

  addEvents() {
    this.button.addEventListener('click', this.onButtonClick)

    window.addEventListener('loaded-elt', this.onEltLoaded)
  }

  onEltLoaded() {
    this.loadedElts += 1

    const targetProgress = this.loadedElts / this.promises.length * 100

    this.progressTo(targetProgress)
  }

  progressTo(target) {
    gsap.to(this.currentProgress, {
      value: target,
      duration: 3,
      onUpdate: () => {
        this.buttonValue.textContent = `${Math.ceil(this.currentProgress.value)}%`
      },
      onComplete: () => {
        if (target === 100) this.switchLoaderToStartButton()
      }
    })
  }

  switchLoaderToStartButton() {
    this.splitTexts()

    gsap.to(this.splittedButtonValue[0].chars, {
      delay: 0.2,
      y: -20,
      alpha: 0,
      duration: 0.6,
      stagger: 0.05,
      ease: 'power2.out'
    })

    gsap.set(this.buttonText, { alpha: 1 })

    gsap.to(this.splittedButtonText[0].chars, {
      delay: 0.15,
      y: -20,
      alpha: 1,
      duration: 0.6,
      stagger: 0.05,
      ease: 'power2.out',
      onStart: () => {
        this.button.disabled = false
        this.canLeave = true
      }
    })
  }

  onButtonClick() {
    if (!this.canLeave) return

    this.requestFullscreen()
    this.leave()
  }

  requestFullscreen() {
    if (document.documentElement.requestFullScreen) document.documentElement.requestFullScreen()
    else if (document.documentElement.webkitRequestFullScreen) document.documentElement.webkitRequestFullScreen()
    else if (document.documentElement.mozRequestFullScreen) document.documentElement.mozRequestFullScreen()
    else if (document.documentElement.msRequestFullscreen) document.documentElement.msRequestFullscreen()
    else if (document.documentElement.webkitEnterFullscreen) document.documentElement.webkitEnterFullscreen()
  }

  leave() {
    gsap.to(this.el, {
      alpha: 0,
      duration: 0.3,
      onComplete: () => {
        this.el.remove()
      }
    })

    window.removeEventListener('loaded-elt', this.onEltLoaded)
    this.onStepEnd()
  }
}