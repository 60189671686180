import globalVariables from '../globalVariables'
import Splitting from 'splitting'
import { gsap } from 'gsap'

export default class ScanSection {
  constructor({ el, onStepEnd, name }) {
    this.bindMethods()

    this.el = el
    this.name = name
    this.onStepEnd = onStepEnd

    this.onMarkerFoundFirstTime = this.onMarkerFoundFirstTime.bind(this)
    this.onMarkerLost = this.onMarkerLost.bind(this)
  }

  showText() {
    return new Promise((resolve) => {
      this.splittedText = Splitting({ target: this.text, by: 'words' })

      gsap.set(this.text, { alpha: 1 })
      gsap.to(this.splittedText[0].words, {
        delay: 0.5,
        y: 0,
        alpha: 1,
        ease: 'expo.out',
        duration: 0.8,
        stagger: 0.075,
        onComplete: () => { resolve() }
      })
    })
  }

  bindMethods() {
    this.leave = this.leave.bind(this)
  }

  onArenaButtonClick(button) {
    if (button.classList.contains('ar')) {
      if (button.textContent === 'AR NOT SUPPORTED') this.leave()
      else {
        globalVariables.startImmersiveAR()
        setTimeout(() => this.hideAR(button), 1000)
      }
    } else this.leave()
  }

  hideAR(currentButton) {
    Array.from(this.buttons).forEach((aButton) => {
      aButton.style.display = aButton === currentButton ? 'none' : 'inline-block'
    })

    this.text.style.display = 'none'
    this.cardImg.style.display = 'none'
    this.bottom.style.zIndex = -1
    this.bottom.style.height = '100vh'
    this.buttonWrapper.style.position = 'absolute'
    this.buttonWrapper.style.width = '100%'
    this.buttonWrapper.style.bottom = '0'
  }

  getElems() {
    this.buttons = this.el.querySelectorAll('button')
    this.text = this.el.querySelector('.scan__text')
    this.cardImg = this.el.querySelector('.scan__card')
    this.cards = this.el.querySelectorAll('.scan__card-inner img')
    this.bottom = this.el.querySelector('.scan__bottom')
    this.buttonWrapper = this.el.querySelector('.scan__button')
  }

  addEvents() {
    for (let i = 0; i < this.buttons.length; i++) {
      if (this.name === 'arena') this.buttons[i].addEventListener('click', this.onArenaButtonClick.bind(this, this.buttons[i]))
      else this.buttons[i].addEventListener('click', this.leave) 
    }
  }

  onMarkerFoundFirstTime(name) {
    gsap.to(this.el, {
      alpha: 0,
      pointerEvents: 'none',
      duration: 0.4,
      ease: 'power2.out'
    })

    this.currentModel = globalVariables.models.filter((aModel) => aModel.name === name)[0]

    gsap.fromTo(this.currentModel.materials, {
      opacity: 0,
    }, {
      opacity: 1,
      ease: 'power2.out',
      duration: 0.6,
      onStart: () => { this.currentModel.model.visible = true }
    })

    this.currentMarker = globalVariables.markers.filter((aFilter) => aFilter.name === name)[0]

    if (this.name === 'cards') {
      this.markers.forEach((aMarker) => {
        if (aMarker !== this.currentMarker) {
          aMarker.stopTracking()
        }
      })
    }
  }

  onMarkerLost() {
    gsap.to(this.el, {
      alpha: 1,
      pointerEvents: 'all',
      duration: 0.4,
      ease: 'power2.out',
      overwrite: true
    })

    gsap.set(this.currentModel.materials, { opacity: 0 })

    this.currentModel.model.visible = false

    if (this.name === 'cards') {
      this.markers.forEach((aMarker) => {
        if (aMarker !== this.currentMarker) {
          aMarker.activeTracking()
        }
      })
    }
  }

  enter() {
    this.getElems()
    this.addEvents()

    if (this.name === 'cards') {
      gsap.fromTo(this.el, {
        display: 'flex',
        alpha: 0
      }, {
        alpha: 1,
        duration: 0.3
      })

    } else this.el.classList.remove('hide')

    Promise.all([
      this.showText(),
      this.showCards()
    ]).then(() => {
      this.enableButtons()
      this.markers = []

      if (this.name === 'arena') {
        document.dispatchEvent(new CustomEvent('create-immersive-ar'))
      } else {
        if (this.name === 'cards') {
          this.colonelNFTMarker = globalVariables.markers.filter((aMarker) => aMarker.name === 'colonel')[0]
          this.destroyerNFTMarker = globalVariables.markers.filter((aMarker) => aMarker.name === 'destroyer')[0]
          this.pantherNFTMarker = globalVariables.markers.filter((aMarker) => aMarker.name === 'panther')[0]
    
          this.markers.push(this.colonelNFTMarker)
          this.markers.push(this.destroyerNFTMarker)
          this.markers.push(this.pantherNFTMarker)
      
          this.colonelNFTMarker.activeTracking()
          this.destroyerNFTMarker.activeTracking()
          this.pantherNFTMarker.activeTracking()
    
        } else if (this.name === 'paragon') {
          this.paragonNFTMarker = globalVariables.markers.filter((aMarker) => aMarker.name === 'paragon')[0]
          this.markers.push(this.paragonNFTMarker)
          this.paragonNFTMarker.activeTracking()
        }
  
        globalVariables.onNFTMarkerFoundFirstTime.push(this.onMarkerFoundFirstTime)
        globalVariables.onNFTMarkerLost.push(this.onMarkerLost)
      }
    })
  }

  enableButtons() {
    for (let i = 0; i < this.buttons.length; i++) {
      this.buttons[i].disabled = false
    }
  }

  disableButtons() {
    for (let i = 0; i < this.buttons.length; i++) {
      this.buttons[i].disabled = true
    }
  }

  showCards() {
    return new Promise((resolve) => {
      if (!this.cards) resolve()

      gsap.fromTo(this.cards, {
        y: '50%',
        x: '-50%',
      },
      {
        delay: 0.95,
        y: '0%',
        x: '0%',
        alpha: 1,
        duration: 0.8,
        ease: 'power2.out',
        stagger: 0.1,
        onComplete: () => { resolve() }
      })
    })
  }

  hideCards() {
    return new Promise((resolve) => {
      if (!this.cards) resolve()

      gsap.to(this.cards, {
        y: '-30%',
        x: '30%',
        alpha: 0,
        duration: 0.4,
        ease: 'power2.out',
        stagger: 0.075,
        onComplete: () => { resolve() }
      })
    })
  }

  hideText() {
    return new Promise((resolve) => {
      gsap.to(this.splittedText[0].words, {
        alpha: 0,
        duration: 0.4,
        ease: 'power2.out',
        onComplete: () => { resolve() }
      })
    })
  }

  hideButtons() {
    gsap.to(this.buttons, {
      alpha: 0,
      pointerEvents: 'none',
      duration: 0.4
    })
  }

  leave() {
    this.disableButtons()

    if (this.name === 'arena') this.hideButtons()

    Promise.all([
      this.hideText(),
      this.hideCards()
    ]).then(() => {
      this.el.style.display = 'none'
      this.el.remove()
      this.onStepEnd()
    })

    if (this.name !== 'arena') {
      const foundIndex = globalVariables.onNFTMarkerFoundFirstTime.indexOf(this.onMarkerFoundFirstTime)
      const lostIndex = globalVariables.onNFTMarkerLost.indexOf(this.onMarkerLost)

      globalVariables.onNFTMarkerFoundFirstTime.splice(foundIndex, 1)
      globalVariables.onNFTMarkerLost.splice(lostIndex, 1)

      this.markers && this.markers.forEach((aMarker, index) => {
        aMarker.stopTracking()
        globalVariables.markers.splice(index, 1)
      })
    }
  }
}
