import Camera from './Camera'
import WebglContext from './WebglContext'
import ARManager from './ar/ARManager'
import StepManager from './steps/StepManager'

export default class App {
  constructor() {
    this.stepManager = new StepManager()
    this.webglContext = new WebglContext()

    this.camera = new Camera()

    this.camera.start().then((video) => {
      this.arManager = new ARManager(video, video.videoWidth, video.videoHeight, this.webglContext)
      this.stepManager.currentStep.start()
    })
  }
}
