export default class NFTMarker {
  constructor({ worker, name, id }) {
    this.worker = worker
    this.id = Number(id)
    this.name = name

    this.stopTracking()
  }

  activeTracking() {
    console.log(`Active tracking ${this.name}`)
    this.worker.postMessage({ type: "start-tracking", id: this.id })
  }

  stopTracking() {
    console.log(`Stop tracking ${this.name}`)
    this.worker.postMessage({ type: "stop-tracking", id: this.id })
  }
}
