export default class ARButton {
	constructor({ renderer, sessionInit }) {
		this.renderer = renderer
		this.sessionInit = sessionInit

		this.bindMethods()
	}

	bindMethods() {
		this.onSessionEnded = this.onSessionEnded.bind(this)
		this.onSessionStarted = this.onSessionStarted.bind(this)
	}

	createARButton() {
		return new Promise((resolve) => {
			this.button = document.createElement('button')
			this.button.id = 'ARButton'
			this.button.style.display = 'none'

			navigator.xr.isSessionSupported('immersive-ar')
			.then((supported) => {
				supported ?
					this.showStartAR() :
					this.showARNotSupported()

					resolve(this.button)
			}).catch(() => {
				this.showARNotSupported()

				resolve(this.button)
			})
		})
	}

	createMessageButton() {
		const message = document.createElement( 'a' )

		message.href = 'https://immersiveweb.dev/'
		message.innerHTML = 'WEBXR NOT AVAILABLE'

		return message
	}

	createButton() {
		return new Promise((resolve) => {
		if ('xr' in navigator) resolve(this.createARButton())
		else resolve(this.createMessageButton())
		})
	}

	addOverlay() {
		const overlay = document.createElement('div')

		overlay.style.display = 'none'
		document.body.appendChild(overlay)

		const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
		const path = document.createElementNS('http://www.w3.org/2000/svg', 'path')

		svg.setAttribute('width', 38)
		svg.setAttribute('height', 38)
		svg.style.position = 'absolute'
		svg.style.right = '20px'
		svg.style.top = '20px'

		path.setAttribute('d', 'M 12,12 L 28,28 M 28,12 12,28')
		path.setAttribute('stroke', '#fff')
		path.setAttribute('stroke-width', 2)

		svg.appendChild(path)
		overlay.appendChild(svg)

		svg.addEventListener('click', () => {
			this.currentSession.end()
		})

		if (this.sessionInit.optionalFeatures === undefined) this.sessionInit.optionalFeatures = []

		this.sessionInit.optionalFeatures.push('dom-overlay')
		this.sessionInit.domOverlay = { root: overlay }
	}

	showStartAR() {
		if (this.sessionInit.domOverlay === undefined) this.addOverlay()

		this.currentSession = null

		this.button.style.display = '';

		this.button.textContent = 'START AR'
	}

	onButtonClick() {
		if (this.currentSession === null) navigator.xr.requestSession('immersive-ar', this.sessionInit).then(this.onSessionStarted)
		else this.currentSession.end()
	}

	onSessionStarted(session) {
		session.addEventListener('end', this.onSessionEnded)

		this.renderer.xr.setReferenceSpaceType('local')
		this.renderer.xr.setSession(session)

		this.button.textContent = 'STOP AR'
		this.sessionInit.domOverlay.root.style.display = ''

		this.currentSession = session
	}

	onSessionEnded() {
		this.currentSession.removeEventListener('end', this.onSessionEnded)

		this.button.textContent = 'START AR'
		this.sessionInit.domOverlay.root.style.display = 'none'

		this.currentSession = null
	}

	disableButton() {
		this.button.style.display = ''

		this.button.onmouseenter = null
		this.button.onmouseleave = null

		this.button.onclick = null
	}

	showARNotSupported() {
		this.disableButton()
		this.button.textContent = 'AR NOT SUPPORTED'
	}
}
