export default class Camera {
  constructor() {
    window.addEventListener('destroy-camera', () => {
      this.mediaSteam.getTracks().forEach((track) => track.stop())
      this.sourceVideo.pause()
    })
  }

  start() {
    return new Promise(resolve => {
      console.log('init ARToolkitNFT...');

      this.initCamera().then((video) => {
        // start camera playback
        this.sourceVideo = video
        this.sourceVideo.width = 640
        this.sourceVideo.height = 480
        this.sourceVideo.play()

        // init target canvas
        this.initTargetCanvas(this.sourceVideo)

        this.sourceVideo.addEventListener('loadeddata', (event) => {
          console.log("Camera is ready")
          resolve(video)
        })
      })
    })
  }

  initCamera() {
    return new Promise((resolve) => {
      const constraints = {
        audio: false,
        video: {
          facingMode: "environment",
          // facingMode: "user",
          width: 640,
          height: 480
        }
      }
    
      // initialize video source
      const video = document.querySelector('#video')
      
      navigator.mediaDevices.getUserMedia(constraints)
      .then((mediaSteam) => {
        this.mediaSteam = mediaSteam
        video.srcObject = this.mediaSteam
        video.onloadedmetadata = () => { resolve(video) }
       })
      .catch((err) => {
        if (err.name === 'NotAllowedError') {
          alert("Error: allow access to camera in settings")
        } else alert(err.name + ": " + err.message)
      })
    })
  }

  initTargetCanvas(sourceVideo) {
    const targetCanvas = document.querySelector("#canvas")

    targetCanvas.width = sourceVideo.width
    targetCanvas.height = sourceVideo.height
  }
}